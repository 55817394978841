import React from 'react'
import PropTypes from 'prop-types'
import "./scss/addons.scss"
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const Addons = ({ addons }) => (
  <div className={'addons'}>
    {addons.map((item, index) => (
      <div className={'addon'} key={index}>
        {typeof item.heading_text !== 'undefined' &&
          <h3 className={'addon__heading'}>{item.heading_text}</h3>}
        <div className={'addon__image'}>
          {typeof item.image !== 'undefined' &&
            <PreviewCompatibleImage imageInfo={{
              image: item.image,
              className: 'addon__image'
            }}
            />}
        </div>
        {typeof item.footer_text !== 'undefined' &&
          <div className={'addon__footer'}>{item.footer_text}</div>}
      </div>
    ))}
  </div>
)

Addons.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading_text: PropTypes.string,
      image: PropTypes.object,
      footer_text: PropTypes.string,
    })
  ),
}

export default Addons
